
import { Options, Vue } from "vue-class-component";

import Page from "@views/page/components/page.vue";
// import LogsView from "@views/page/logs-view.vue";
// import ContentLogsDetailView from "@views/page/content-logs/components/content-logs-detail-view.vue"
import LogsViewFilter from "@/views/components/filter-component/logs-view-filter.vue";
import PageRequest from "@/dataSource/api/pagination-helper/lib/PageRequest";
import FilterModel from "../components/filter-model";
import getUtc from "@/filters/getUtc";
import GetLogDetailsResponse from "@/dataSource/api/logger-micro-service/services/models/response/log-getter-service/GetLogDetailsResponse";
import EnumLogType from "@/dataSource/api/tirscript/logger-micro-service/enums/EnumLogType";
import EnumLogLevel from "@/dataSource/api/tirscript/logger-micro-service/enums/EnumLogLevel";
import ItemDetale from "@views/page/components/item-detale.vue";
import GetActiveLogDetailsResponse from "@/dataSource/api/logger-micro-service/services/models/response/log-getter-service/GetActiveLogDetailsResponse";

@Options<ContentLogsDetailPage>({
  components: {
    Page,
    LogsViewFilter,
    ItemDetale,
    // ContentLogsDetailView
  },
  props: { slotProps: {} },

  watch: {
    //параметры заходящие через слот
    //пока только идентификатор источника логов(sourceLogId)
    slotProps: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value?.sourceLogId) {
          this.filterModel.source = value.sourceLogId;
        }
      },
    },
  },
})
export default class ContentLogsDetailPage extends Vue {
  readonly slotProps = {};
  pagination: PageRequest;
  requestId: string = "";
  item: GetLogDetailsResponse | GetActiveLogDetailsResponse | any = null;
  // itemDataLogs: GetLogDataResponse = null;
  isLoading = false;
  filterModel: FilterModel = new FilterModel();
  filterModelView: FilterModel = null;

  error1 = {
    isSuccess: true,
    errorMessage: "",
  };
  error2 = {
    isSuccess: true,
    errorMessage: "",
  };

  getUtc = getUtc;

  async mounted() {
    // this.getInLocalStor();
    // // подписываемся на событие отфильтровать в компоненте Page
    // document.addEventListener(
    //   "filterChange",
    //   this.onclickFilterChange.bind(this)
    // );
    this.requestId = String(this.$route.params.id);
    //await this.getLogDetails()
    await this.getLogDetails();
    this.isLoading = false;
    //чтобы скопировать значение, а ссылку  и исключить реактивность. Обновление только по кнопке отфильтровать.
    // this.filterModelView = JSON.parse(JSON.stringify(this.filterModel));
  }

  getLocalStorageInfo() {
    if (localStorage.contentLogsRequestId) {
      this.requestId = localStorage.getItem("contentLogsRequestId");
      this.getLogDetails();
    } else {
      this.$router.back();
    }
  }

  async getLogDetails() {
    this.isLoading = true;
    let request;
    try {
      if (this.$route.params?.isActive == "true") {
        request = await this.$api.LogGetterService.getActiveLogDetailsAsync(
          `"${this.requestId}"`
        );
      } else {
        request = await this.$api.LogGetterService.getLogDetailsAsync(
          `"${this.requestId}"`
        );
      }
    } catch (error) {
      this.$notification.error(error.ErrorMessage);
      this.error1.isSuccess = false;
      this.error1.errorMessage = error;
      this.isLoading = false;
    }

    if (request) this.item = request;
  }

  onclickFilterChange() {
    // ловим событие нажатия кнопки отфильтровать
    this.filterModel.DateTo = this.filterModel.DateTo;
    this.filterModel.DateFrom = this.filterModel.DateFrom;
    this.filterModelView = JSON.parse(JSON.stringify(this.filterModel));
    //сохраняем в локал сторедж состояние фильтра для текущей страницы
    this.saveInLocalStor();
  }

  //сохранить модель фильтра для страницы
  saveInLocalStor() {
    localStorage.otherLogsViewPage = JSON.stringify(this.filterModelView);
  }

  //получить данные фильтра для страницы если они есть
  getInLocalStor() {
    if (localStorage.otherLogsViewPage) {
      this.filterModel = JSON.parse(localStorage.otherLogsViewPage);
      const t = 0// new Date().getTimezoneOffset();
      this.filterModel.DateFrom = this.addMinutes(this.filterModel.DateFrom, t);
      this.filterModel.DateTo = this.addMinutes(this.filterModel.DateTo, t);
      // this.filterModel.DateTo = new Date(this.filterModel.DateTo);
    }
  }

  addMinutes(date, minutes) {
    return new Date(new Date(date).getTime() + minutes * 60000);
  }

  getLogType(type: EnumLogType) {
    return EnumLogType[type];
  }
  getLogLevel(level: EnumLogLevel) {
    return EnumLogLevel[level];
  }

  //var date = convertUTCDateToLocalDate(new Date(date_string_you_received));
}
